<template>
  <a-card :bordered="false">
    <div>
      <a-page-header
        style="border: 1px solid rgb(235, 237, 240); margin-bottom: 35px"
        title="商品列表"
        :sub-title="order_no"
        @back="() => $router.go(-1)"
      >
        <a-row type="flex">
        </a-row>
      </a-page-header>
      <s-table
        ref="table"
        size="small"
        rowKey="id"
        :loading="loading"
        :columns="columns"
        :data="loadData"
        :showPagination="false"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <div slot="count_render" slot-scope="text" style="text-align: right; min-width: 90px; max-width: 160px">
          {{ text }}
        </div>
      </s-table>
    </div>
  </a-card>
</template>

<script>
import { STable } from '@/components'
import { Dictionaries } from '@/common/AllConstant'

import {
  customs_inventory_order_list
} from '@/api/customs_order'
export default {
  name: 'TableList',
  components: {
    STable
  },
  props: {},
  data () {
    return {
      order_no: '',
      loading: false,
      confirmLoading: false,
      current_bill_id: 0,
      // 创建窗口控制
      visible: false,
      mdl: {},
      payconfirmLoading: false,
      // 创建窗口控制
      payvisible: false,
      paymdl: {},
      dictionaries: Dictionaries,
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          align: 'center',
          width: 50,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '商品名称',
          dataIndex: 'gname'
        },
        {
          title: '数量',
          align: 'center',
          dataIndex: 'qty',
          scopedSlots: { customRender: 'count_render' }
        },
        {
          title: '规格',
          align: 'center',
          dataIndex: 'gmodel'
        },
        {
          title: '单价',
          align: 'center',
          dataIndex: 'price',
          scopedSlots: { customRender: 'count_render' }
        },
        {
          title: '单位',
          align: 'center',
          dataIndex: 'unit',
          customRender: (text) => this.$Dictionaries.unit[text]
        },
        {
          title: '法定计量单位',
          align: 'center',
          dataIndex: 'unit1',
          customRender: (text) => this.$Dictionaries.unit[text]
        },
        {
          title: '原产国',
          align: 'center',
          customRender: (text) => this.$Dictionaries.country[text] || '无',
          dataIndex: 'country'
        }
        // {
        //   title: '单位',
        //   align: 'center',
        //   customRender: (text) => this.$Dictionaries.BondedCommodityChangeCategory[text],
        //   dataIndex: 'category'
        // }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        console.log('电费参数', parameter)
        return customs_inventory_order_list(this.$route.params.order_id)
          .then(({ data }) => {
            console.log(data, '我是数据列表@')
            const localData = { entries: [] }
            localData.entries = data
            return localData
          })
      },
      selectedRowKeys: [],
      selectedRows: []
    }
  },
  created () {

  },
  methods: {}
}
</script>
